<template>
	<el-dialog width="400px" class="draggable-dialog videoInstructionsModal" title="Instructiuni video"
		:show-close="true" :visible="videoInstructionsActive" :close-on-press-escape="false" :modal="false"
		:modal-append-to-body="false" :close-on-click-modal="false" :append-to-body="true" v-draggable
		@close="closeVideoInstructions">
		<div class="videoWrapper">
			<iframe :src="`${videoInstructionsLink}?modestbranding=1&autohide=1&showinfo=0`" frameborder="0"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowfullscreen></iframe>
		</div>
	</el-dialog>
</template>

<script>
	import DlgDraggable from '@/directives/Draggable';
	import Vue from 'vue';
	Vue.use(DlgDraggable);

	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {
				dialogTableVisible: true
			};
		},
		watch: {

		},
		computed: {
			...mapGetters({
				'videoInstructionsActive': 'app/videoInstructionsActive',
				'videoInstructionsLink': 'app/videoInstructionsLink'
			})
		},
		methods: {
			closeVideoInstructions() {
				this.$store.commit('app/DISABLE_VIDEO_INSTRUCTIONS');
			}
		}
	};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
	.videoWrapper {
		height: 150px;

		iframe {
			position: absolute;
			top: 60px;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
</style>